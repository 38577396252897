import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';

import { PasswordStrengthMeterService } from './password-strength-meter.service';

@Component({
  selector: 'password-strength-meter',
  template: `<div>
  <div class="strength-meter">
    <div class="strength-meter-fill" [style.background]="getMeterFillColor(passwordStrength)" [attr.data-strength]="passwordStrength"></div>
  </div>
  <ng-container *ngIf="enableFeedback && feedback">
    <small class="password-feedback" *ngIf="feedback.warning">
      {{feedback.warning}}
    </small>
    <small class="password-feedback" *ngIf="feedback.suggestions && feedback.suggestions.length > 0">
      <ng-container *ngFor="let suggetion of feedback.suggestions">{{suggetion}}</ng-container>
    </small>
  </ng-container>
</div>
`,
  styles: [`.strength-meter{position:relative;height:3px;background:#ddd;margin:10px auto;border-radius:3px}.strength-meter:after,.strength-meter:before{content:'';height:inherit;background:0 0;display:block;border-color:#fff;border-style:solid;border-width:0 5px;position:absolute;width:85px;z-index:10}.strength-meter:before{left:70px}.strength-meter:after{right:70px}.strength-meter-fill{background:0 0;height:inherit;position:absolute;width:0;border-radius:inherit;transition:width .5s ease-in-out,background .25s}.strength-meter-fill[data-strength='0']{background:#8b0000;width:20%}.strength-meter-fill[data-strength='1']{background:#ff4500;width:40%}.strength-meter-fill[data-strength='2']{background:orange;width:60%}.strength-meter-fill[data-strength='3']{background:#9acd32;width:80%}.strength-meter-fill[data-strength='4']{background:green;width:100%}.password-feedback{font-size:70%;font-weight:400;color:#6c757d!important;display:inline-block;margin-top:.25rem}`],
  providers: [PasswordStrengthMeterService]
})
export class PasswordStrengthMeterComponent implements OnInit, OnChanges {
  @Input() password: string;

  @Input() minPasswordLength = 8;

  @Input() enableFeedback = false;

  @Input() colors: string[] = [];

  @Output() strengthChange = new EventEmitter<number>();

  passwordStrength: number = null;

  feedback: { suggestions: string[]; warning: string } = null;

  private prevPasswordStrength = null;

  private defaultColours = [
    'darkred',
    'orangered',
    'orange',
    'yellowgreen',
    'green'
  ];

  constructor(
    private passwordStrengthMeterService: PasswordStrengthMeterService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['password']) {
      this.calculatePasswordStrength();
    }
  }

  private calculatePasswordStrength() {
    // TODO validation logic optimization
    if (!this.password) {
      this.passwordStrength = null;
    } else if (this.password && this.password.length < this.minPasswordLength) {
      this.passwordStrength = 0;
    } else {
      if (this.enableFeedback) {
        const result = this.passwordStrengthMeterService.scoreWithFeedback(
          this.password
        );
        this.passwordStrength = result.score;
        this.feedback = result.feedback;
      } else {
        this.passwordStrength = this.passwordStrengthMeterService.score(
          this.password
        );
        this.feedback = null;
      }
    }

    // Only emit the passwordStrength if it changed
    if (this.prevPasswordStrength !== this.passwordStrength) {
      this.strengthChange.emit(this.passwordStrength);
      this.prevPasswordStrength = this.passwordStrength;
    }
  }

  getMeterFillColor(strength) {
    if (!strength || strength < 0 || strength > 5) {
      return this.colors[0] ? this.colors[0] : this.defaultColours[0];
    }

    return this.colors[strength]
      ? this.colors[strength]
      : this.defaultColours[strength];
  }
}
